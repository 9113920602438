@import "assets/base";
@import "~@ng-select/ng-select/themes/default.theme.css";

//@import "~@ng-select/ng-select/themes/material.theme.css";

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');

// Apply the Inter font to the body
body {
  font-family: 'Inter', sans-serif;
}

.card{
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}
/* General Table Styling */
table {
  width: 100%;
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

th, td {
  padding: 12px 15px;
  text-align: left;
}

th {
  background: #426295 !important;
  color: #ffffff;
  font-weight: bold;
}

tbody tr {
  background-color: #ffffff; /* Default white background for contrast */
}

tbody tr:nth-of-type(even) {
  background-color: #f8f9fc; /* Slightly off white for striped effect */
}

tbody tr:hover {
  background-color: #f4f4f8; /* A light hover state for interactivity */
}

/* Highlight Columns for Emphasis */
td{
}

/* Vibrant Role Badge Styling */
.role-badge {
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 0.75em;
  font-weight: bold;
  color: white;
  text-align: center;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.role-admin {
  background: linear-gradient(135deg, #ff7979, #eb4d4b);
}

.role-user {
  background: linear-gradient(135deg, #7ed6df, #22a6b3);
}

.role-guest {
  background: linear-gradient(135deg, #f9ca24, #f0932b);
}

/* Action Button Styling */
.action-btn {
  padding: 8px 12px;
  border-radius: 4px;
  border: none;
  color: #fff;
  font-size: 0.8em;
  cursor: pointer;
  transition: transform 0.3s ease;
  margin: 0 5px; /* Space out buttons */
}

.edit-btn {
  background-color: #4cd137; /* A green color that pops */
}

.edit-btn:hover {
  transform: translateY(-3px); /* Subtle hover effect */
}

.delete-btn {
  background-color: #e84118; /* A red color for caution */
}

.delete-btn:hover {
  transform: translateY(-3px); /* Subtle hover effect */
}

/* Responsive */
@media screen and (max-width: 600px) {
  table {
    display: block;
    overflow-x: auto;
  }
}
/* Overriding ngx-toastr success background color */
.toast-success {
  background-color: #426295 !important;
}

/**/
.card-1 {
  background-image: url('./assets/components/card1.png');
  color: white;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.card-2 {
  background-image: url('./assets/components/card2.png');
  background-size: cover;
  color: white;
  background-repeat: no-repeat;
  background-position: center;
}
/*.text-center.d-block.p-3.card-footer {
  background: #ff914d;
}

.p2bj{
  background: #f1f4f6;
}*/
.table-hover > tbody > tr:hover {
  --bs-table-accent-bg: #8db3ce !important;
  color: var(--bs-table-hover-color) !important;
}
.widget-content .widget-numbers {
  font-weight: 400;
  font-size: 1.8rem;
  display: block;
}
.page-title{
  font-weight: 600;
  color: #4c6fbf;
}
